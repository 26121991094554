"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";

function CommonProvider({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  useEffect(() => {}, [pathname]);
  return <>{children}</>;
}

export { CommonProvider };
