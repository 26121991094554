"use client";

import { Divider, Paper, ThemeIcon } from "@mantine/core";
import { createTheme, MantineColorsTuple } from "@syde-ui";

const blue: MantineColorsTuple = [
  "#EBF1FF",
  "#EBF1FF",
  "#EBF1FF",
  "#C2D6FF",
  "#C2D6FF",
  "#375DFB",
  "#375DFB",
  "#253EA7",
  "#253EA7",
  "#162664",
];

const orange: MantineColorsTuple = [
  "#FEF3EB",
  "#FEF3EB",
  "#FEF3EB",
  "#FFDAC2",
  "#FFDAC2",
  "#F17B2C",
  "#F17B2C",
  "#C2540A",
  "#C2540A",
  "#6E330C",
];

const yellow: MantineColorsTuple = [
  "#FEF7EC",
  "#FEF7EC",
  "#FEF7EC",
  "#FBDFB1",
  "#FBDFB1",
  "#F2AE40",
  "#F2AE40",
  "#B47818",
  "#B47818",
  "#693D11",
];

const red: MantineColorsTuple = [
  "#FDEDF0",
  "#FDEDF0",
  "#FDEDF0",
  "#F8C9D2",
  "#F8C9D2",
  "#DF1C41",
  "#DF1C41",
  "#AF1D38",
  "#AF1D38",
  "#710E21",
];

const green: MantineColorsTuple = [
  "#EFFAF6",
  "#EFFAF6",
  "#EFFAF6",
  "#CBF5E5",
  "#CBF5E5",
  "#38C793",
  "#38C793",
  "#2D9F75",
  "#2D9F75",
  "#176448",
];

const purple: MantineColorsTuple = [
  "#f3edff",
  "#e0d7fa",
  "#beabf0",
  "#9a7ce6",
  "#7c56de",
  "#683dd9",
  "#5f2fd8",
  "#4f23c0",
  "#451eac",
  "#3a1899"
]

const pink: MantineColorsTuple = [
  "#FDEBFF",
  "#FDEBFF",
  "#FDEBFF",
  "#F9C2FF",
  "#F9C2FF",
  "#E255F2",
  "#E255F2",
  "#9C23A9",
  "#9C23A9",
  "#620F6C",
];

const teal: MantineColorsTuple = [
  "#EBFAFF",
  "#EBFAFF",
  "#EBFAFF",
  "#C2EFFF",
  "#C2EFFF",
  "#35B9E9",
  "#35B9E9",
  "#1F87AD",
  "#1F87AD",
  "#164564",
];

const dark: MantineColorsTuple = [
  "#525866",
  "#525866",
  "#31353F",
  "#20232D",
  "#161922",
  "#0A0D14",
  "#0A0D14",
  "#0A0D14",
  "#0A0D14",
  "#0A0D14",
];

const gray: MantineColorsTuple = [
  "#F6F8FA",
  "#F6F8FA",
  "#E2E4E9",
  "#CDD0D5",
  "#868C98",
  "#868C98",
  "#525866",
  "#525866",
  "#31353F",
  "#31353F",
];

export const theme = createTheme({
  /* Put your mantine theme override here */
  colors: { blue, orange, yellow, red, green, purple, pink, teal, dark, gray },
  fontFamily: "Inter, sans-serif",
  primaryColor: "purple",
  defaultRadius: "md",
  components: {
    Divider: Divider.extend({
      defaultProps: {
        styles: {
          root: {
            borderColor: "var(--mantine-color-gray-2)",
          },
        },
      }
    }),
    Paper: Paper.extend({
      defaultProps: {
        radius: "lg",
        styles: {
          root: {
            borderColor: "var(--mantine-color-gray-2)",
          },
        },
        withBorder: true,
      },
    }),
    ThemeIcon: ThemeIcon.extend({
      defaultProps: {
        styles: {
          root: {
            borderColor: "var(--mantine-color-gray-3)",
          },
        },
      },
    }),
  }
});
